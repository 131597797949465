<template>
  <div class="home">
    <div class="banner">
      <div class="home-header">
        <div class="home-herder-container">
          <div class="header-left"></div>
          <div class="header-right">
            <ul class="header-right-menu">
              <li>首页</li>
              <li @click="goPage('render-service')">企业服务</li>
              <li><router-link to="/about_us">关于我们</router-link></li>
              <li>加盟我们</li>
              <li @click="goPage('footer')">联系我们</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="banner-title">
        <div class="banner-content">
          <h2>全球跨境企业服务综合运营商</h2>
          <span>
            基于同系公司WAY-CURRSIGHT多年搭建的完整创业服务体系能够大幅度降低项目在境内落地的难度</span
          >
        </div>
      </div>
    </div>
    <div id="render-service" class="body1">
      <div class="body1-container">
        <div class="body1-title">
          <h2>提供服务</h2>
          <span>RENDER SERVICE</span>
        </div>
        <div class="body1-describe">快速解决境内落地的一系列难题</div>
        <div class="body1-group">
          <div class="group-left">
            <div class="up">
              <div class="category">企业服务</div>
            </div>
            <div class="down">
              <div class="item">工商财税</div>
              <div class="item">知产法律</div>
              <div class="item">技术咨询</div>
            </div>
          </div>
          <div class="group-right">
            <div class="up">
              <div class="category">地方政府</div>
            </div>
            <div class="down">
              <div class="item">
                <div>
                  <p>场地厂房</p>
                  <p>财政支持</p>
                </div>
              </div>
              <div class="item">
                <div>
                  <p>政府扶持</p>
                  <p>政策</p>
                </div>
              </div>
              <div class="item">
                <div>
                  <p>人才引进</p>
                  <p>政策</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body2">
      <div class="body2-container">
        <div class="body2-title">
          <h2>核心优势</h2>
          <span>KEY STRENGTH</span>
        </div>
        <div class="body2-describe">快速解决境内落地的一系列难题</div>
        <div class="body2-group">
          <div class="body2-item">强大的服务交付覆盖能力</div>
          <div class="body2-item">大规模运营的最佳实践和数据积累</div>
          <div class="body2-item">丰富经验的服务管理团队和专家资源池</div>
          <div class="body2-item">成熟的运营管理体系和云支撑平台</div>
          <div class="body2-item">具备深厚的设施管理方法论</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  methods: {
    //锚点跳转
    goPage(anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background: url("../../public/img/bg1-2.jpg") center center no-repeat;
  background-size: 100%;
  width: 100%;
  height: 750px;
  //height: 100vh;
  min-width: 1600px;
}

.home-header {
  width: 100%;
  height: 150px;
}

.home-herder-container {
  width: 1600px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-left {
    background: url("../../public/img/logo.png") 0 0;
    background-size: 100%;
    height: 133px;
    width: 135px;
    //height: 102px;
    margin-left: 80px;
  }

  .header-right {
    padding-right: 150px;
    color: white;
    font-size: 18px;
  }

  .header-right-menu {
    display: flex;
    width: 445px;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    a {
      color: white;
      text-decoration: none;
    }
  }
}

.banner-title {
  width: 100%;
  height: 150px;
  margin-top: 150px;
}

.banner-content {
  width: 1600px;
  height: 100%;
  margin: 0 auto;
  color: white;

  h2,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 75px;
    font-size: 24px;
  }

  h2 {
    font-size: 84px;
    font-weight: 700;
    letter-spacing: 24px;
  }

  span {
    //letter-spacing: 8px;
    margin-top: 50px;
    color: rgba(255, 255, 255, 0.8);
  }
}

.body1 {
  padding-top: 20px;
  width: 100%;
  height: 800px;
  //margin-top: 130px;
  //background-color: rgba(69, 59, 59, 0.2);
  color: #797979;
}

.body1-container {
  width: 1600px;
  height: 100%;
  margin: 0 auto;
}

.body1-title {
  width: 100%;

  h2,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h2 {
    font-size: 30px;
    color: #4a7c9d;
    font-weight: 700;
  }

  span {
    font-size: 14px;
    position: relative;
    color: #797979;
  }
}

.body1-describe {
  width: 1150px;
  text-align: center;
  margin: 50px auto 0;
  color: #797979;
  font-size: 20px;
}

.body1-group {
  width: 100%;
  height: 360px;
  margin: 50px auto 0;
  display: flex;

  .group-left,
  .group-right {
    flex: 1;

    .up {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .category {
        color: white;
        width: 200px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        box-shadow: 0 0 13px -5px #666;
        border-radius: 10px;
        font-weight: 600;
        background-color: #4a7c9d;
      }
    }

    .down {
      height: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .item {
        color: white;
        flex: 1;
        height: 120px;
        box-shadow: 0 0 13px -5px #666;
        border-radius: 10px;
        margin: 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        background-color: rgba(74, 124, 157, 0.8);

        div {
          text-align: center;
        }
      }
    }
  }
  .group-right {
    flex: 1;
  }

  .body1-item {
    width: 22%;
    margin-right: 4%;
    //border: 1px solid #ccc;
    box-shadow: 0 0 9px -4px #666666;
    margin-bottom: 20px;
    border-bottom: 1px solid #666;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-of-type(4) {
      margin-right: 0;
    }
    &:nth-of-type(8) {
      margin-right: 0;
    }
  }
}

.body2 {
  width: 100%;
  min-width: 1600px;
  height: 760px;
  background-color: #2895a0;
}

.body2-container {
  width: 1600px;
  height: 100%;
  margin: 0 auto;
  padding-top: 125px;
}

.body2-title {
  @extend .body1-title;
  h2,
  span {
    color: white;
  }
}

.body2-describe {
  @extend .body1-describe;
  color: white;
}

.body2-group {
  width: 1075px;
  height: 380px;
  margin: 30px auto 0;
  display: flex;
}

.body2-item {
  flex: 1;
  margin-right: 10px;
  color: white;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 170px 10px 0;
  font-size: 14px;
  &:last-child {
    margin-right: 0;
  }

  &:nth-of-type(1) {
    background: url("../../public/img/body2-li1.png") center center;
    background-size: 100%;
  }
  &:nth-of-type(2) {
    background: url("../../public/img/body2-li2.png") center center;
    background-size: 100%;
  }
  &:nth-of-type(3) {
    background: url("../../public/img/body2-li3.png") center center;
    background-size: 100%;
  }
  &:nth-of-type(4) {
    background: url("../../public/img/body2-li4.png") center center;
    background-size: 100%;
  }
  &:nth-of-type(5) {
    background: url("../../public/img/body2-li5.png") center center;
    background-size: 100%;
  }
}

.body5 {
  width: 100%;
  height: 640px;
}

.body5-container {
  width: 1600px;
  height: 100%;
  padding: 0 150px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.body5-item {
  width: 33.33%;
  height: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
  }

  p {
    text-align: center;
    margin-top: 10px;
    color: #666666;
  }
}
</style>
